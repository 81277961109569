import { createSlice } from "@reduxjs/toolkit";
import { getUsers, blockUnblockUser, deleteUser } from "actions/usersActions";

const initialState = {
  data: [],
  totalCount: 0,
  isUsersFetching: false,
  isUpdatingUserStatus: false,
  isDeletingUser: false,
};

export const departmentSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isUsersFetching = true;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.totalCount = payload.meta.total;
      })
      .addCase(getUsers.rejected, (state) => {
        state.isUsersFetching = false;
      })
      .addCase(blockUnblockUser.pending, (state) => {
        state.isUpdatingUserStatus = true;
      })
      .addCase(blockUnblockUser.fulfilled, (state) => {
        state.isUpdatingUserStatus = false;
      })
      .addCase(blockUnblockUser.rejected, (state) => {
        state.isUpdatingUserStatus = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isDeletingUser = true;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.isDeletingUser = false;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.isDeletingUser = false;
      })
  },
});

export default departmentSlice.reducer;
