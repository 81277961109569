import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUsers, blockUnblockUser, deleteUser } from "actions/usersActions";

import { getColumns } from "./columns";
import { PrimaryTable } from "components/PrimaryTable";
import {
  CONSTANTS,
  DEFAULT_DATE_REVERSED_SORT_PARAMS,
  DEFAULT_DATE_SORT_PARAMS,
  SEARCH_KEY,
  TABLE_MIN_PAGE,
  TABLE_PER_PAGE_COUNT,
  USER_WARNING_MESSAGES,
  defaultReversedSortParams,
} from "constants/general";
import WarningDialogue from "components/WarningDialogue";
import Notify from "helpers/Notify";
import { NOTIFY } from "constants/notifyType";
import { getError } from "helpers/getError";
import { DIALOGUE } from "./constants";
import { ComponentHeader } from "components";
import { Filter } from "./components";
import FilterHelpers from "helpers/filter";
import { defaultSortParams } from "constants/general";

const emptyDialogueState = { type: "", userId: null };

const userFilters = [
  {
    column: "Status",
    queryKey: "blocked",
    valueName: "blocked",
    values: [
      { name: "Not Blocked", value: 0 },
      { name: "Blocked", value: 1 },
    ],
  },
];

let appliedFilterParams = {};

export const UsersList = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [goToPage, setGoToPage] = useState("");
  const [sort, setSort] = useState(defaultSortParams);
  const [openDialogue, setOpenDialogue] = useState(emptyDialogueState);
  const [sortModelState, setSortModelState] = useState(
    DEFAULT_DATE_SORT_PARAMS
  );

  const query = new URLSearchParams(useLocation().search);
  const search = query.get(SEARCH_KEY);

  const blockUnblockAction = (params) => dispatch(blockUnblockUser(params));
  const deleteUserAction = (params) => dispatch(deleteUser(params));

  const openWarningDialogue = ({ type, userId }) => {
    setOpenDialogue({ type, userId });
  };

  const columns = getColumns(openWarningDialogue);

  const users = useSelector((state) => state.users.data);
  const totalCount = useSelector((state) => state.users.totalCount);

  const totalPageNumber = Math.ceil(totalCount / TABLE_PER_PAGE_COUNT);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleGoToPageChange = (event) => {
    setGoToPage(event.target.value);
  };

  const goToSpecificPage = () => {
    const newPage = Number(goToPage);
    if (newPage >= TABLE_MIN_PAGE && newPage <= totalPageNumber) {
      setPage(newPage);
    }
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length) {
      setSortModelState(sortModel);
      setSort({ order_by: sortModel[0].field, order: sortModel[0].sort });
    } else {
      setSortModelState(DEFAULT_DATE_REVERSED_SORT_PARAMS);
      setSort(defaultReversedSortParams);
    }
  };

  const handleConfirmDialogue = () => {
    appliedFilterParams = appliedFiltersToParams(appliedFilters);
    if (openDialogue.type === DIALOGUE.delete) {
      try {
        deleteUserAction({
          id: openDialogue.userId,
          page,
          search,
          sort,
          appliedFilterParams,
        });
      } catch (err) {
        Notify(NOTIFY.type.error, getError(err));
      }
    }
    if (
      openDialogue.type === DIALOGUE.block ||
      openDialogue.type === DIALOGUE.unblock
    ) {
      try {
        blockUnblockAction({
          id: openDialogue.userId,
          page,
          search,
          sort,
          appliedFilterParams,
        });
      } catch (err) {
        Notify(NOTIFY.type.error, getError(err));
      }
    }
  };

  const {
    handleApplyFilter,
    handleRemoveFilter,
    handleClearFilters,
    appliedFiltersToParams,
    appliedFilters,
  } = FilterHelpers();

  useEffect(() => {
    appliedFilterParams = appliedFiltersToParams(appliedFilters);
    dispatch(getUsers({ page, search, ...sort, ...appliedFilterParams }));
  }, [dispatch, page, search, sort, appliedFilters]);

  useEffect(() => {
    setPage(CONSTANTS.FIRST_PAGE);
  }, [search]);

  return (
    <div className="table_page_body_container">
      <ComponentHeader noMargin />
      {!!openDialogue.type && (
        <WarningDialogue
          open={!!openDialogue.type}
          close={() => setOpenDialogue(emptyDialogueState)}
          confirmHandler={handleConfirmDialogue}
          message={USER_WARNING_MESSAGES[openDialogue.type]}
          title={openDialogue.type}
        />
      )}
      <PrimaryTable
        filterComponent={
          <Filter
            filtersList={userFilters}
            applyFilter={handleApplyFilter}
            appliedFilters={appliedFilters}
            setTablePage={setPage}
          />
        }
        handleClearFilters={handleClearFilters}
        handleRemoveFilter={handleRemoveFilter}
        appliedFilters={appliedFilters}
        rows={users}
        columns={columns}
        handlePageChange={handlePageChange}
        handleGoToPageChange={handleGoToPageChange}
        totalPageNumber={totalPageNumber}
        page={page}
        goToPage={goToPage}
        goToSpecificPage={goToSpecificPage}
        handleSortModelChange={handleSortModelChange}
        sortModel={sortModelState}
        hasPagination={totalCount > TABLE_PER_PAGE_COUNT}
      />
    </div>
  );
};
